import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
import { Icon } from '@iconify/react';
import locationIcon from '@iconify/icons-mdi/thermometer';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

import { formatRelative } from "date-fns";

import "@reach/combobox/styles.css"
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow, 
} from "@react-google-maps/api";

const libraries = ["places"];
const mapContainerStyle = {
  width: '100vw',
  height: '100vh',
}
const center = {
  lat: 45.83288,
  lng: -97.26266
}

export default function App() {

  const [selectedStation, setSelectedStation] = useState(null);

  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GoogleMapAPIKey,
    libraries,
  });

  const [eventData, setEventData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true)
      const res = await fetch(process.env.REACT_APP_APIEndPoint)
      const evt = await res.json()

      setEventData(evt)
      setLoading(false)
    }

    fetchEvents()
  }, [])

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  return (
    <div>
            {/* <h1>
        Weather Station{" "}
        <span role="img" aria-label="umbrella">
        ☔
        </span>
      </h1> */}
      <Locate panTo={panTo} />
        <GoogleMap 
          mapContainerStyle={mapContainerStyle}
          zoom={8}
          center={center}
          options={{gestureHandling: 'greedy', mapTypeId: 'hybrid'}}
          onLoad={onMapLoad}
        >
          {eventData.map(station => (
            <Marker
              key={station.id}
              position={{
                lat: parseFloat(station.latitude),
                lng: parseFloat(station.longitude),
              }}
              onClick={() => {
                setSelectedStation(station);
              }}
              icon={{
                url: "/thermometer.svg"
              }}
          />
        ))}

        {selectedStation && (
          <InfoWindow
            position={{
              lat: parseFloat(selectedStation.latitude),
              lng: parseFloat(selectedStation.longitude),
            }}
            onCloseClick={() => {
              setSelectedStation(null);
            }}
          >

            <div>
              <h2>Station: {selectedStation.id}</h2>
              <p>Last Updated: {moment.utc(selectedStation.logtime).local().startOf('seconds').fromNow()}</p>
              <p>Temp: {Number(selectedStation.temperature).toFixed(1)} F</p>
              <p>Humidity: {Number(selectedStation.humidity).toFixed(1)}%</p>
              <p>Wind Speed: {Number(selectedStation.wind).toFixed(1)} mph</p>
              <p>Wind Direction: {selectedStation.winddirection}</p>
              <p>Rain: {Number(selectedStation.rain).toFixed(1)} "</p>

            </div>
          </InfoWindow>
        )}
        ></GoogleMap>
  </div>
  )
}

function Locate({ panTo }) {
  return (
    <button
      className="locate"
      onMouseDown={() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            panTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => null
        );
      }}
    >
      <img src="/location_icon.svg" alt="locator" />
    </button>
  );
}